// Import
@import "import";

body {
    font-family: 'Open Sans', Helvetica sans-serif;

    .header {
        height: 150px;
        background-size: cover;
        background-color: $brand-primary;

        .container, .container > .row, .col-sm-12 {
            height: 100%;
        }

        .logo {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            .img-logo {
                max-width: 340px;
                background-color: #fff;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: $negative-margin;
                box-shadow: 0 2px 4px rgba(0,0,0, .16);

                img {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    .btn-primary {
        transition: all .2s;
        border: 0;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        box-shadow: 0 2px 4px rgba(0,0,0, .16);
        color: #fff;

        &:hover {
            color: $btn-color-hover;
            background-color: $btn-bg-hover;
        }
    }

    .main {
        padding-top: 110px;

        h1 {
            text-align: center;
            font-size: 1.8em;
            padding-bottom: 20px;
            margin: 0 auto;
            font-weight: 700;
            line-height: 1.2;
        }
        p{
            color: $gray-light;
            text-align: center;
            font-weight: 400 !important;
            line-height: 1.5;
            margin-bottom: 40px;

            strong{
                color: black;
            }
        }

        .link {
            text-align: center;
            padding-top: 35px;

            a {
                width: 100%;
                margin-bottom: 20px;
                padding: 15px 30px;
            }
        }

        .img-1 {
            padding-top: 20px;
            padding-bottom: 20px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .gallery {
        padding-top: 35px;
        h2 {
            font-size: 1.5em;
            max-width: 400px;
            margin: 0 auto 40px;
            text-align: center;
        }
        .gallery-content{
            .gallery-item {
                padding-bottom: 15px;
                img {
                    width: 100%;
                    height: auto;
                }

            }
        }
    }

    .footer {
        margin-top: 10px;
        background-color: $footer-bg;

        .copyright{
            margin: 10px;
            display: block;
            text-align: start;
            color: #fff;

        }

        .wdi {
            margin: 10px;
            display: block;
            text-align: end;

            a {
                img {
                    width: 20px !important;
                }
            }
        }
    }

    .page-wrapper {
        display: flex;
        flex-flow: row wrap;
        align-content: space-between;
        height: 100%;

        .header, .main, .footer {
            flex: 1 1 100%;
        }
    }
    
    footer.footer {
        padding-bottom: 20px;
    }
}
